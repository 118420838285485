$navbarIncludeB2C: 0;
@import 'colors';
@import 'variables';
@import 'utilities';

@import '~bootstrap/scss/bootstrap';
@import '~react-toastify/scss/main';
@import '~swiper/scss';
@import '~swiper/scss/navigation';
@import '~swiper/scss/pagination';

@import 'typography';

@import '@components/atoms/atoms';
@import '@components/molecules/molecules';
@import '@components/organisms/organisms';

:root {
  --bs-body-bg: rgb(243, 247, 247);
  --bs-link-color: var(--bs-gray-900);
  --bs-link-color-rgb: var(--bs-gray-900);
  --bs-link-hover-color-rgb: var(--bs-gray-900);
  --bs-body-font-size: .875rem;
  --bs-border-radius: .25rem;
  --bs-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .1) !important;
  --bs-body-font-family: var(--font-open-sans);

  --toastify-z-index: 2147483647;
}


::-webkit-scrollbar {
  width: 5px
}

::-webkit-scrollbar-track {
  background: #f1f1f1
}

::-webkit-scrollbar-thumb {
  background: #888
}

::-webkit-scrollbar-thumb:hover {
  background: #555
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield
}

option {
  font-family: arial;
}

.cursor--pointer {
  cursor: pointer;
}

.list-group {
  --bs-list-group-bg: transparent;
  --bs-list-group-border-color: none;
  --bs-list-group-item-padding-x: 0;
  --bs-list-group-item-padding-y: 0;


  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #ffffff;
  --bs-list-group-active-bg: #ff9a25;
  --bs-list-group-active-border-color: #ff9a25;
}

hr {
  color: $gray-200;
  border-color: $gray-200;
  background-color: $gray-200;
  opacity: 1;
}

.backdrop {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  &.backdrop--linear-gradient__1 {
    background-image: linear-gradient(to right, rgb(0, 0, 0), transparent)
  }
}

.vertical-hr {
  width: 1px;
  height: 30px;
  background-color: $gray-700;
}

.bg-rectangle {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, .15), transparent 24px), linear-gradient(to bottom, var(--bs-gray-900) 0%, var(--bs-gray-900) 100%);
}

.text-truncate-2 {
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.text-truncate.text-truncate-2 {
  white-space: normal;
}

.pb-6 {
  padding-bottom: ($spacer * 3.75) !important;
}

.px-10 {
  padding-left: ($spacer * 10) !important;
  padding-right: ($spacer * 10) !important;
}

.mh-auto {
  min-height: auto;
}

@include media-breakpoint-up(xl) {
  .px-xl-10 {
    padding-left: ($spacer * 10) !important;
    padding-right: ($spacer * 10) !important;
  }
}

@include media-breakpoint-up(xxl) {
  .px-xxl-10 {
    padding-left: ($spacer * 10) !important;
    padding-right: ($spacer * 10) !important;
  }
}

.ff-base {
  font-family: var(--font-open-sans) !important
}

.flex-basis-auto {
  flex-basis: auto !important
}


.card {
  --bs-card-bg: rgb(255, 255, 255);
}

.table {
  --bs-table-bg: var(--bs-white);
  --bs-table-striped-bg: var(--bs-gray-100);
}

.dropdown-menu {
  --bs-dropdown-bg: var(--bs-white);
  --bs-dropdown-min-width: calc(100% - var(--bs-gutter-x));
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-bg: var(--bs-secondary);
  scrollbar-width: thin;
  max-height: 60vh;
  overflow-y: auto;
}

.dropdown-toggle,
.dropup .dropdown-toggle {
  &:after {
    width: 12px;
    height: 12px;
    border: none;
    background-image: url(/Icons/icon-chevron-down.svg);
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
  }
}

.dropup .dropdown-toggle,
.dropdown-toggle.show {
  &:after {
    background-image: url(/Icons/icon-chevron-up.svg);
  }
}

i {
  &.flag {

    &:not(.icon),
    &:not(.icon):before {
      display: inline-block;
      width: 16px;
      height: 11px;
    }

    &:not(.icon):before {
      content: '';
      background: url(/flags.png) no-repeat -108px -1976px;
    }

    &:before {
      display: inline-block;
      width: 16px;
      height: 11px;
      content: '';
      background: url(/flags.png) no-repeat -108px -1976px;
    }

    &.flag-de,
    &.flag-germany,
    &.flag-at,
    &.flag-austria,
    &.flag-ch,
    &.flag-switzerland {
      &:before {
        background-position: 0 -1430px !important;
      }
    }

    &.flag-at,
    &.flag-austria {
      &:before {
        background-position: 0 -286px !important;
      }
    }

    &.flag-ch,
    &.flag-switzerland {
      &:before {
        background-position: 0 -1066px !important;
      }
    }
  }
}

.-ms-1px {
  margin-left: -1px !important;
}


@include media-breakpoint-up(md) {
  .border-md-2 {
    border-width: 2px !important;
  }

  @each $color, $value in $theme-colors {
    .border-md-#{$color} {
      border-color: $value !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .collapsible-md[type=checkbox]~label {
    cursor: pointer;
  }

  .collapsible-md[type=checkbox]:not(:checked)~.collapsible-content {
    height: 0;
    overflow: hidden;
    @include transition($transition-collapse);
  }

  .collapsible-md[type=checkbox]:checked~.collapsible-content {
    height: auto;
  }


  .collapsible-md[type=checkbox]:checked~label {
    .icon {
      &:before {
        background-image: url("/Icons/icon-chevron-up.svg");
      }
    }
  }
}